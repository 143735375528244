import AdminLayout from '@/views/layouts/admin'
import AccountPage from '@/views/admin/account/show'
import DashboardPage from '@/views/admin/home/dashboard'
import ProfilePage from '@/views/admin/account/show'
// sessions
import SessionsPage from '@/views/admin/sessions/all'
import NewSessionsPage from '@/views/admin/sessions/new'
import EditSessionsPage from '@/views/admin/sessions/edit'

// users
import UsersPage from '@/views/admin/users/all'
import NewUsersPage from '@/views/admin/users/new'
import EditUsersPage from '@/views/admin/users/edit'
// coupons
import CouponsPage from '@/views/admin/plans/coupons/all'
import NewCouponsPage from '@/views/admin/plans/coupons/new'
import EditCouponsPage from '@/views/admin/plans/coupons/edit'
// plans
import PlansPage from '@/views/admin/plans/all'
import NewPlansPage from '@/views/admin/plans/new'
import EditPlansPage from '@/views/admin/plans/edit'

// roles
import RolePage from '@/views/admin/users/roles/all'
import NewRolePage from '@/views/admin/users/roles/new'
import EditRolePage from '@/views/admin/users/roles/edit'
// programmes
import ProgrammePage from '@/views/admin/programs/all'
import NewProgrammePage from '@/views/admin/programs/new'
import EditProgrammePage from '@/views/admin/programs/edit'
import ShowProgrammePage from '@/views/admin/programs/show'

// categories
import CategoriesPage from '@/views/admin/categories/all'
import NewCategoriesPage from '@/views/admin/categories/new'
import EditCategoriesPage from '@/views/admin/categories/edit'

import TransactionsPage from '@/views/admin/transactions/all'

import schedulePage from '@/views/admin/schedule/all'
import NewschedulePage from '@/views/admin/schedule/new'
import EditschedulePage from '@/views/admin/schedule/edit'

//ads

import AdsPage from '@/views/admin/ads/all'
import NewAdsPage from '@/views/admin/ads/new'
import EditAdsPage from '@/views/admin/ads/edit'

// hosts

import HostsPage from '@/views/admin/hosts/all'
import NewHostsPage from '@/views/admin/hosts/new'
import EditHostsPage from '@/views/admin/hosts/edit'

import BackgroundsPage from '@/views/admin/backgrounds/all'
import NewBackgroundsPage from '@/views/admin/backgrounds/new'
import EditBackgroundsPage from '@/views/admin/backgrounds/edit'

import SplashsPage from '@/views/admin/splashs/all'
import NewSplashsPage from '@/views/admin/splashs/new'
import EditSplashsPage from '@/views/admin/splashs/edit'

export default {
    path: '/admin',
    component: AdminLayout,
    redirect: '/admin/users',
    meta: {
        requiresAuth: true,
        is_admin: true
    },
    children: [{
            path: 'dashboard',
            name: 'Dashboard',
            meta: { layout: 'admin', title: 'Dashboard', permissions: ['dashboard', 'admin'] },
            component: DashboardPage,
        },
        {
            path: 'profile',
            name: 'Profile',
            meta: { layout: 'admin', title: 'Profile', permissions: ['profile', 'admin'] },
            component: ProfilePage,
        },

        {
            path: 'users',
            name: 'Users',
            meta: { layout: 'admin', title: 'Users', permissions: ['user:read', 'admin'] },
            component: UsersPage,
        },
        {
            path: 'users/new',
            name: 'NewUser',
            meta: { layout: 'admin', title: 'Users', permissions: ['user:create', 'admin'] },
            component: NewUsersPage,
        },
        {
            path: 'users/:id/edit',
            name: 'EditUser',
            meta: { layout: 'admin', title: 'Users', permissions: ['user:edit', 'admin'] },
            component: EditUsersPage,
        },
        {
            path: 'splashs',
            name: 'Splashs',
            meta: { layout: 'admin', title: 'Splashs', permissions: ['splash:read', 'admin'] },
            component: SplashsPage,
        },
        {
            path: 'splashs/new',
            name: 'NewSplash',
            meta: { layout: 'admin', title: 'Splashs', permissions: ['splash:create', 'admin'] },
            component: NewSplashsPage,
        },
        {
            path: 'splashs/:id/edit',
            name: 'EditSplash',
            meta: { layout: 'admin', title: 'Splashs', permissions: ['splash:edit', 'admin'] },
            component: EditSplashsPage,
        },
        {
            path: 'backgrounds',
            name: 'Backgrounds',
            meta: { layout: 'admin', title: 'Backgrounds', permissions: ['background:read', 'admin'] },
            component: BackgroundsPage,
        },
        {
            path: 'backgrounds/new',
            name: 'NewBackground',
            meta: { layout: 'admin', title: 'Backgrounds', permissions: ['background:create', 'admin'] },
            component: NewBackgroundsPage,
        },
        {
            path: 'backgrounds/:id/edit',
            name: 'EditBackground',
            meta: { layout: 'admin', title: 'Backgrounds', permissions: ['background:edit', 'admin'] },
            component: EditBackgroundsPage,
        },
        {
            path: 'ads',
            name: 'Ads',
            meta: { layout: 'admin', title: 'Ads', permissions: ['ad:read', 'admin'] },
            component: AdsPage,
        },
        {
            path: 'ads/new',
            name: 'NewAd',
            meta: { layout: 'admin', title: 'Ads', permissions: ['ad:create', 'admin'] },
            component: NewAdsPage,
        },
        {
            path: 'ads/:id/edit',
            name: 'EditAd',
            meta: { layout: 'admin', title: 'Ads', permissions: ['ad:edit', 'admin'] },
            component: EditAdsPage,
        },
        {
            path: 'hosts',
            name: 'Hosts',
            meta: { layout: 'admin', title: 'Hosts', permissions: ['host:read', 'admin'] },
            component: HostsPage,
        },
        {
            path: 'hosts/new',
            name: 'NewHost',
            meta: { layout: 'admin', title: 'Hosts', permissions: ['host:create', 'admin'] },
            component: NewHostsPage,
        },
        {
            path: 'hosts/:id/edit',
            name: 'EditHost',
            meta: { layout: 'admin', title: 'Hosts', permissions: ['host:edit', 'admin'] },
            component: EditHostsPage,
        },
        {
            path: 'roles',
            name: 'Roles',
            meta: { layout: 'admin', title: 'Role', permissions: ['role:read', 'admin'] },
            component: RolePage,
        },
        {
            path: 'roles/new',
            name: 'NewRole',
            meta: { layout: 'admin', title: 'Role', permissions: ['role:create', 'admin'] },
            component: NewRolePage,
        },
        {
            path: 'roles/:id/edit',
            name: 'EditRole',
            meta: { layout: 'admin', title: 'Role', permissions: ['role:edit', 'admin'] },
            component: EditRolePage,
        },
        {
            path: 'account',
            name: 'AAccount',
            meta: { layout: 'admin', title: 'AAccount' },
            component: AccountPage,
        },
        {
            path: 'plans',
            name: 'APlans',
            meta: { layout: 'admin', title: 'Plans', permissions: ['plan:read', 'admin'] },
            component: PlansPage,
        },
        {
            path: 'plans/new',
            name: 'NPlans',
            meta: { layout: 'admin', title: 'Plans', permissions: ['plan:create', 'admin'] },
            component: NewPlansPage,
        },
        {
            path: 'plans/:id/edit',
            name: 'EditPlans',
            meta: { layout: 'admin', title: 'Plans', permissions: ['plan:edit', 'admin'] },
            component: EditPlansPage,
        },
        {
            path: 'coupons',
            name: 'Coupon',
            meta: { layout: 'admin', title: 'Coupon', permissions: ['coupon:read', 'admin'] },
            component: CouponsPage,
        },
        {
            path: 'coupons/new',
            name: 'NCoupon',
            meta: { layout: 'admin', title: 'Coupon', permissions: ['coupon:create', 'admin'] },
            component: NewCouponsPage,
        },
        {
            path: 'coupons/:id/edit',
            name: 'EditCoupon',
            meta: { layout: 'admin', title: 'Coupon', permissions: ['coupon:edit', 'admin'] },
            component: EditCouponsPage,
        },
        {
            path: 'sessions',
            name: 'Sessions',
            meta: { layout: 'admin', title: 'Sessions', permissions: ['episode:read', 'admin'] },
            component: SessionsPage,
        },
        {
            path: 'sessions/new',
            name: 'NSessions',
            meta: { layout: 'admin', title: 'Sessions', permissions: ['episode:create', 'admin'] },
            component: NewSessionsPage,
        },
        {
            path: 'sessions/:id/edit',
            name: 'EditSession',
            meta: { layout: 'admin', title: 'Sessions', permissions: ['episode:edit', 'admin'] },
            component: EditSessionsPage,
        },

        {
            path: 'programmes',
            name: 'Programmes',
            meta: { layout: 'admin', title: 'Programmes', permissions: ['programme:read', 'admin'] },
            component: ProgrammePage,
        },
        {
            path: 'programmes/new',
            name: 'NProgramme',
            meta: { layout: 'admin', title: 'Programmes', permissions: ['programme:create', 'admin'] },
            component: NewProgrammePage,
        },
        {
            path: 'programmes/:id/edit',
            name: 'EditProgramme',
            meta: { layout: 'admin', title: 'Programmes', permissions: ['programme:edit', 'admin'] },
            component: EditProgrammePage,
        },
        {
            path: 'programmes/:id',
            name: 'ShowProgramme',
            meta: { layout: 'admin', title: 'Programmes', permissions: ['programme:read', 'admin'] },
            component: ShowProgrammePage,
        },

        {
            path: 'categories',
            name: 'Categories',
            meta: { layout: 'admin', title: 'Categories', permissions: ['category:read', 'admin'] },
            component: CategoriesPage,
        },
        {
            path: 'categories/new',
            name: 'NCategory',
            meta: { layout: 'admin', title: 'Categories', permissions: ['category:create', 'admin'] },
            component: NewCategoriesPage,
        },
        {
            path: 'categories/:id/edit',
            name: 'EditCategory',
            meta: { layout: 'admin', title: 'Categories', permissions: ['category:Edit', 'admin'] },
            component: EditCategoriesPage,
        },
        {
            path: 'schedules/new',
            name: 'Newschedule',
            meta: { layout: 'admin', title: 'schedule', permissions: ['schedule:create', 'admin'] },
            component: NewschedulePage,
        },
        {
            path: 'schedules',
            name: 'schedule',
            meta: { layout: 'admin', title: 'schedule', permissions: ['schedule:read', 'admin'] },
            component: schedulePage,
        },
        {
            path: 'schedules/:id/edit',
            name: 'Edischedule',
            meta: { layout: 'admin', title: 'schedule', permissions: ['schedule:edit', 'admin'] },
            component: EditschedulePage,
        },
        {
            path: 'payments',
            name: 'Transactions',
            meta: { layout: 'admin', title: 'Transactions', permissions: ['payment:read', 'admin'] },
            component: TransactionsPage,
        },
    ]
}