// src/aws-config.js
import AWS from 'aws-sdk';

// Initialize AWS Cognito Identity credentials
AWS.config.region = process.env.VUE_APP_AWSRegion; // e.g. 'us-east-1'
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.VUE_APP_IdentityPoolId // e.g. 'us-east-1:xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
});

const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: { Bucket: process.env.VUE_APP_AWSBucket}
});

export { s3 };
